// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Progress, Col, Popover } from 'antd';
import { Row } from 'components/layout';

const ApplicationProgressStats = props => {
  const { data, t, applicationData, module } = props;

  if (!data) return;

  const colorApp = {
    regular: '#0FC5D8',
    fade: '#0FC5D81A',
    reject: '#999999',
  };

  const totalApplications =
    module === 'projects' ? data.total_e_applications : data.total_m_applications;
  const completionRate = Math.round((applicationData.submitted / totalApplications) * 100);
  const applicationCompletition = Math.round((applicationData.submitted / totalApplications) * 100);
  const droppedRate =
    applicationData.dropped && Math.round((applicationData.dropped / totalApplications) * 100);

  return (
    <Wrapper color={colorApp}>
      <Row type="flex" justify="space-between" gutter={20}>
        <Col>
          <div className="text">
            {t('App. In Progress')}
            <span data-test-id="app-in-progress">{applicationData.in_progress}</span>
          </div>
          <div className="text">
            {t('App Submitted')}
            <span data-test-id="app-submitted">{applicationData.submitted}</span>
          </div>
        </Col>
        <Col>
          <div className="text">
            {t('Completion Rate')}
            <span data-test-id="completion-rate">{completionRate} %</span>
          </div>
          <div className="text">
            {t('Dropped Rate')}
            <span style={{ color: colorApp.reject }} data-test-id="dropped-rate">
              {droppedRate} %
            </span>
          </div>
        </Col>
      </Row>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              minWidth: '150px',
            }}
          >
            {t('Total Application')}
            <span style={{ color: colorApp.regular }}>
              {applicationData.in_progress + applicationData.submitted}
            </span>
          </div>
        }
        trigger="hover"
      >
        <Progress
          strokeColor={colorApp.regular}
          percent={applicationCompletition || 0}
          showInfo={false}
        />
      </Popover>
    </Wrapper>
  );
};

ApplicationProgressStats.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  applicationData: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: ${({ singleCol }) => (singleCol ? '180px' : '380px')};
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 ${({ color }) => color.fade};
    color: ${({ color }) => color.regular};
    .text {
      min-width: 120px;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      justify-content: space-between;
      color: #000000;
      margin-bottom: 5px;
      span {
        color: ${({ color }) => color.regular};
        margin-left: 20px;
      }
    }
    .ant-progress-inner {
      background-color: ${({ color }) => color.fade};
    }
  }
`;

export default ApplicationProgressStats;
