import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';
import {
  PM_STATUS_APPLICATION,
  PM_STATUS_SELECTION,
  PM_STATUS_APPROVED,
  PM_STATUS_MATCHING,
  PM_STATUS_INCUBATION,
  PM_STATUS_ALUMNI,
  PM_STATUS_DROPPED,
  PM_STATUS_REJECTED,
} from 'models/MemberApplication';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: {
      [PM_STATUS_APPLICATION]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_SELECTION]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_APPROVED]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_MATCHING]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_INCUBATION]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_ALUMNI]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_DROPPED]: {
        results: [],
        count: 0,
      },
      [PM_STATUS_REJECTED]: {
        results: [],
        count: 0,
      },
    },
    applicationById: {},
    loading: false,
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.APPLICATION_FETCH:
    case types.MEMBER_APPLICATIONS_LIST:
    case types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH:
    case types.MEMBER_APPLICATION_BULK_UPDATE:
    case types.MEMBER_APPLICATION_BULK_UPDATE_INVITE:
    case types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo({ updatePending: true }));
    case types.MEMBER_APPLICATIONS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            [action.programPhase]: {
              ...action.payload,
            },
          },
          loading: false,
          updatePending: false,
        })
      );
    case types.APPLICATION_FETCH_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          applicationById: { ...action.payload },
        })
      );
    case types.MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED_INVITE: {
      const { fields, ids } = action.payload;
      const updatedResult = state.data[action.programPhase].results.map(project => {
        if (ids.includes(project.id)) {
          const newProject = { ...project, status: fields.status };
          return newProject;
        }

        return project;
      });
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            [action.programPhase]: {
              count: state.data[action.programPhase].count,
              results: updatedResult,
            },
            loading: false,
            updatePending: false,
          },
        })
      );
    }
    case types.MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED: {
      const { fields, ids } = action.payload;
      const newResults = state.data[action.programPhase].results.map(app => {
        if (ids.includes(app.id)) {
          const newApp = { ...app, ...fields };
          return newApp;
        }
        return app;
      });
      let updatedResult;
      // for the rejected/dropped case, we need to remove the element from the list
      if (fields.hasOwnProperty('dropped') || fields.hasOwnProperty('rejected')) {
        updatedResult = newResults.filter(result => !ids.includes(result.id));
      }
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            [action.programPhase]: {
              results: updatedResult || newResults,
              count: updatedResult ? updatedResult.length : state.data[action.programPhase].count,
            },
            loading: false,
            updatePending: false,
          },
        })
      );
    }
    case types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_SUCCEEDED: {
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: state.data,
        })
      );
    }
    case types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_SUCCEEDED: {
      const { programPhase } = action.payload;
      const index = state.data[programPhase].results.findIndex(
        result => result.id === action.payload.data.id
      );
      const updatedResults = state.data[programPhase].results.toSpliced(index, 1, responseData);
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            [programPhase]: {
              count: state.data[programPhase].count,
              results: updatedResults,
            },
            loading: false,
            updatePending: false,
          },
          loading: false,
        })
      );
    }
    case types.MEMBER_APPLICATIONS_LIST_FAILED:
    case types.MEMBER_APPLICATION_BULK_UPDATE_FAILED:
    case types.MEMBER_APPLICATIONS_LIST_PENDING:
    case types.MEMBER_APPLICATION_BULK_UPDATE_FAILED_INVITE:
    case types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_FAILED:
    case types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          loading: true,
          errorMessage: action.payload,
        })
      );
    case types.CURRENT_APPLICATION:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          current: action.payload,
        })
      );
    default:
      return state;
  }
}
