// Libraries
import styled from 'styled-components';

// Components
import { Tag } from 'antd';

const getBackgroundColor = props => {
  const { color, theme, tool } = props;
  if (color) return `${color}1a`;

  switch (tool) {
    case 1:
      return '#C330001A';
    case 2:
      return '#89043D1A';
    case 3:
      return '#8407E51A';
    case 4:
      return '#2121CE1A';
    case 5:
      return '#227EA31A';
    case 6:
      return '#1A754E1A';
    case 7:
      return '#707d281A';
    case 8:
      return '#B1740F1A';
    default:
      return theme.hyperlinkBgFade;
  }
};

const getTextColor = props => {
  const { color, theme, tool } = props;
  if (color) return color;

  switch (tool) {
    case 1:
      return '#C33000';
    case 2:
      return '#89043D';
    case 3:
      return '#8407E5';
    case 4:
      return '#2121CE';
    case 5:
      return '#227EA3';
    case 6:
      return '#1A754E';
    case 7:
      return '#707d28';
    case 8:
      return '#B1740F';
    default:
      return theme.hyperlink;
  }
};

const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;

  font-size: 14px;
  color: ${getTextColor} !important;
  background-color: ${getBackgroundColor} !important;

  border-radius: ${({ type }) => (type === 'squared' ? '5px' : '20px')};
  border: none;

  padding: ${({ size }) => (size === 'small' ? '0px 5px' : '2px 15px')};
  line-height: ${({ size }) => (size === 'small' ? '20px' : '25px')};

  cursor: default;
`;

export default StyledTag;
