import { adminsApi } from 'services/apiSlices/adminsApi';
import { adminDashboardApi } from 'services/apiSlices/adminDashboardApi';
import { countriesApi, publicCountriesApi, regionsAPI } from 'services/apiSlices/countriesApi';
import { entrepreneursApi } from 'services/apiSlices/entrepreneurApi';
import { interestshipsApi } from 'services/apiSlices/interestshipsApi';
import { languagesApi } from 'services/apiSlices/languagesApi';
import { communityProfilesApi } from 'services/apiSlices/communityProfilesApi';
import { userApi } from 'services/apiSlices/userApi';
import { managementPanelApi } from 'services/apiSlices/managementPanelApi';
import { programsDashboardApi } from 'services/apiSlices/programsDashboardApi';
import { userTokenAPI } from 'services/apiSlices/userTokenApi';
import { parrobotChatApi } from 'services/apiSlices/parrobotChat/parrobotChatApi';
import { organizationsApi } from 'services/apiSlices/organizationsApi';

// E & M Applications & Profile
import { entrepreneurApplicationApi } from 'services/apiSlices/applications/entrepreneurApplicationApi';
import { mentorApplicationApi } from 'services/apiSlices/applications/mentorApplicationApi';
import { areasOfExpertiseApi } from 'services/apiSlices/areasOfExpertiseApi';
import { areasOfInnovationApi } from 'services/apiSlices/areasOfInnovationApi';
import { sectorsApi } from 'services/apiSlices/sectorsApi';

// Programs
import { programsApi } from 'services/apiSlices/programs/programsApi';
import { publicProgramsApi } from 'services/apiSlices/programs/publicProgramsApi';
import { adminDashboardProgramsApi } from 'services/apiSlices/programs/adminDashboardProgramsApi';

// Projects
import { projectsApi } from 'services/apiSlices/projects/projectsApi';
import { publicProjectsApi } from 'services/apiSlices/projects/publicProjectsApi';
import { exploreProjectsApi } from 'services/apiSlices/projects/exploreProjectsApi';

export const middleware = [
  adminsApi.middleware,
  adminDashboardApi.middleware,
  areasOfExpertiseApi.middleware,
  areasOfInnovationApi.middleware,
  countriesApi.middleware,
  publicCountriesApi.middleware,
  regionsAPI.middleware,
  entrepreneurApplicationApi.middleware,
  interestshipsApi.middleware,
  parrobotChatApi.middleware,
  entrepreneursApi.middleware,
  languagesApi.middleware,
  communityProfilesApi.middleware,
  userApi.middleware,
  managementPanelApi.middleware,
  mentorApplicationApi.middleware,
  sectorsApi.middleware,
  userTokenAPI.middleware,
  organizationsApi.middleware,

  // Programs
  programsApi.middleware,
  programsDashboardApi.middleware,
  publicProgramsApi.middleware,
  adminDashboardProgramsApi.middleware,

  // Projects
  projectsApi.middleware,
  publicProjectsApi.middleware,
  exploreProjectsApi.middleware,
];
