import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/profilePicture';
import serviceProfilePicture from 'services/profilePicture';
import { actions as userActions } from 'store/modules/user';

export function* fetchProfilePictures(action) {
  try {
    const customPicture = yield call(serviceProfilePicture.getProfilePicture, action.payload);
    const defaultPictures = yield call(serviceProfilePicture.getDefaultPictures, action.payload);
    const profilePictureData = customPicture.data;
    const defaultProfilePictureData = defaultPictures.data;
    yield put(
      actions.profilePictureSucceeded({
        profilePictureData,
        defaultProfilePictureData,
      })
    );
  } catch (e) {
    yield put(
      actions.userLinkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* createProfilePicture(action) {
  const data = action.payload;
  try {
    const profilePicResponse = yield call(serviceProfilePicture.createProfilePicture, data);
    yield put(actions.profilePictureCreateSucceeded(profilePicResponse.data));

    yield put(
      userActions.userFetchRequest()
    );
  } catch (e) {
    yield put(
      actions.profilePictureCreateFailed({
        message: e.message || e.response.data.detail,
      })
    );
  }
}

export function* editProfilePicture(action) {
  const { id, ...data } = action.payload;
  try {
    yield call(serviceProfilePicture.editProfilePicture, { id, ...data });
    yield put(actions.profilePictureEditSucceeded());
  } catch (e) {
    yield put(
      actions.profilePictureEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* profilePictureSaga() {
  yield all([
    takeLatest(types.PROFILE_PICTURE_REQUEST, fetchProfilePictures),
    takeLatest(types.PROFILE_PICTURE_EDIT, editProfilePicture),
    takeLatest(types.PROFILE_PICTURE_CREATE, createProfilePicture),
  ]);
}

export default profilePictureSaga;
