// Libraries
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Dependencies
import bfbTheme from 'styles/b4b-theme';

// Components
import { Modal as AntModal } from 'antd';

const BasicModal = ({
  title,
  footer = null,
  width = 900,
  headerBgColor = bfbTheme.mainColor,
  headerTextColor = 'white',
  ...rest
}) => (
  <Modal
    title={title}
    footer={footer}
    width={width}
    $headerBgColor={headerBgColor}
    $headerTextColor={headerTextColor}
    // please render the CTA buttons on the footer instead
    cancelButtonProps={{ style: { display: 'none' } }}
    oKButtonProps={{ style: { display: 'none' } }}
    {...rest}
  />
);

const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 0;

    .ant-modal-title {
      color: ${({ $headerTextColor }) => $headerTextColor};
    }

    button.ant-modal-close {
      top: 30px;
      width: 30px;
      height: 30px;
      color: ${({ $headerTextColor }) => $headerTextColor};
    }

    .ant-modal-header {
      padding: 30px;
      background: ${({ $headerBgColor }) => $headerBgColor};
      margin: 0;
    }

    .ant-modal-body {
      padding: 30px;
    }

    .ant-modal-footer {
      padding: 0 30px 30px;
    }
  }
`;

BasicModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  footer: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerBgColor: PropTypes.string,
  headerTextColor: PropTypes.string,
};

export default BasicModal;
