import { Record } from 'immutable';

export const STATUS_IN_PROGRESS = 0; // In Progress (Application)
export const STATUS_PENDING_DECISION = 1; // Pending Decision (Selection)
export const STATUS_APPROVED_NO_EMAIL = 2; // Approved - No email (Approved)
export const STATUS_APPROVED_ONBOARDING_SESSION = 3; // Approved - Onboarding Session (Approved)
export const STATUS_UNMATCHED = 4; // Un-matched (Matching)
export const STATUS_MATCHED = 5; // Matched (Matching)
export const STATUS_INCUBATING = 6; // Incubating (Incubation)
export const STATUS_ALUMNI = 7; // Alumni (Alumni)

export const MENTOR_STATUS_APPLICATION = 'application';
export const MENTOR_STATUS_SELECTION = 'selection';
export const MENTOR_STATUS_APPROVED = 'approved';
export const MENTOR_STATUS_MATCHING = 'matching';
export const MENTOR_STATUS_INCUBATION = 'incubating';
export const MENTOR_STATUS_ALUMNI = 'alumni';
export const MENTOR_STATUS_REJECTED = 'rejected';
export const MENTOR_STATUS_DROPPED = 'dropped';

export const MENTOR_STATUS_MAP = [
  MENTOR_STATUS_APPLICATION,
  MENTOR_STATUS_SELECTION,
  MENTOR_STATUS_APPROVED,
  MENTOR_STATUS_MATCHING,
  MENTOR_STATUS_INCUBATION,
  MENTOR_STATUS_ALUMNI,
  MENTOR_STATUS_REJECTED,
  MENTOR_STATUS_DROPPED,
];

export const getPMStageByApplicationStatus = status => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return MENTOR_STATUS_APPLICATION;
    case STATUS_PENDING_DECISION:
      return MENTOR_STATUS_SELECTION;
    case STATUS_APPROVED_NO_EMAIL:
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return MENTOR_STATUS_APPROVED;
    case STATUS_MATCHED:
    case STATUS_UNMATCHED:
      return MENTOR_STATUS_MATCHING;
    case STATUS_INCUBATING:
      return MENTOR_STATUS_INCUBATION;
    case STATUS_ALUMNI:
      return MENTOR_STATUS_ALUMNI;
    default:
      return undefined;
  }
};

const REJ_INCOMPLETE = 0;
const REJ_PROF_EXPERIENCE = 1;
const REJ_ENT_KNOWLEDGE = 2;
const REJ_LAN_PROFICIENCY = 3;
const REJ_PROGRAM_REQUIREMENTS = 4;
const REJ_OTHER = 5;
export const REJ_REASONS = [
  REJ_INCOMPLETE,
  REJ_PROF_EXPERIENCE,
  REJ_ENT_KNOWLEDGE,
  REJ_LAN_PROFICIENCY,
  REJ_PROGRAM_REQUIREMENTS,
  REJ_OTHER,
];

export const getStatusDisplay = (t, status) => {
  const statusNames = {
    [STATUS_IN_PROGRESS]: t('In Progress'),
    [STATUS_PENDING_DECISION]: t('Pending Decision'),
    [STATUS_APPROVED_NO_EMAIL]: t('Manual Follow-Up'),
    [STATUS_APPROVED_ONBOARDING_SESSION]: t('Onboarding'),
    [STATUS_UNMATCHED]: t('Unmatched'),
    [STATUS_MATCHED]: t('Matched'),
    [STATUS_INCUBATING]: t('Incubating'),
    [STATUS_ALUMNI]: t('Completed'),
  };
  return statusNames[status];
};

export const getStatusDisplayNewPMPanel = (t, status) => {
  const statusNames = {
    [STATUS_IN_PROGRESS]: t('In Progress'),
    [STATUS_PENDING_DECISION]: t('Pending Decision'),
    [STATUS_APPROVED_NO_EMAIL]: t('Manual Follow-Up'),
    [STATUS_APPROVED_ONBOARDING_SESSION]: t('Onboarding'),
    [STATUS_UNMATCHED]: t('Unmatched'),
    [STATUS_MATCHED]: t('Matched'),
    [STATUS_INCUBATING]: t('Incubating'),
    [STATUS_ALUMNI]: t('Completed'),
  };
  return statusNames[status];
};

export const getRejectedStatusDisplay = (t, rejected) => {
  switch (rejected) {
    case REJ_INCOMPLETE:
      return t('Incomplete / weak application');
    case REJ_PROF_EXPERIENCE:
      return t('Lack of professional experience');
    case REJ_ENT_KNOWLEDGE:
      return t('Lack of entrepreneurship knowledge');
    case REJ_LAN_PROFICIENCY:
      return t('Lack of language proficiency');
    case REJ_PROGRAM_REQUIREMENTS:
      return t('Does not meet program requirements');
    case REJ_OTHER:
      return t('Other (no email sent)');
    default:
      return undefined;
  }
};

const DROP_UNRESPONSIVE = 0;
const DROP_NOT_A_FIT = 1;
const DROP_BAD_EXPERIENCE_PM = 2;
const DROP_BAD_EXPERIENCE_E = 3;
const DROP_BAD_TIMING = 4;
const DROP_NOT_INTERESTED_PROJECTS = 5;
const DROP_REJECTED_BY_E = 6;
const DROP_FOR_PROJECT_DROPPED = 7;

export const getDroppedStatusDisplay = (t, dropped) => {
  switch (dropped) {
    case DROP_UNRESPONSIVE:
      return t('Became unresponsive');
    case DROP_NOT_A_FIT:
      return t('Not a fit');
    case DROP_BAD_EXPERIENCE_PM:
      return t('Had a bad experience with Program Manager');
    case DROP_BAD_EXPERIENCE_E:
      return t('Had a bad experience with Entrepreneur');
    case DROP_BAD_TIMING:
      return t('Bad timing');
    case DROP_NOT_INTERESTED_PROJECTS:
      return t('No interest in projects');
    case DROP_REJECTED_BY_E:
      return t('Was rejected by Entrepreneur(s)');
    case DROP_FOR_PROJECT_DROPPED:
      return t('The project was dropped');
    default:
      return undefined;
  }
};

const DROPPED_UNRESPONSIVE = {
  id: DROP_UNRESPONSIVE,
  value: 'becameUnresponsive',
  text: 'Became unresponsive',
};

const DROPPED_NOT_A_FIT = {
  id: DROP_NOT_A_FIT,
  value: 'notAFit',
  text: 'Not a fit',
};

const DROPPED_BAD_EXPERIENCE_PM = {
  id: DROP_BAD_EXPERIENCE_PM,
  value: 'hadABadExperienceWithPM',
  text: 'Had a bad experience with Program Manager',
};

const DROPPED_BAD_ENT_EXPERIENCE = {
  id: DROP_BAD_EXPERIENCE_E,
  value: 'hadABadExperienceWithEntrepreneur',
  text: 'Had a bad experience with Entrepreneur',
};

const DROPPED_BAD_TIMING = {
  id: DROP_BAD_TIMING,
  value: 'badTiming',
  text: 'Bad timing',
};

const DROPPED_NO_INTEREST_IN_PROJECTS = {
  id: DROP_NOT_INTERESTED_PROJECTS,
  value: 'noInterestInProjects',
  text: 'No interest in projects',
};

const DROPPED_REJECTED_BY_ENT = {
  id: DROP_REJECTED_BY_E,
  value: 'wasRejectedByEntrepreneur',
  text: 'Was rejected by Entrepreneur(s)',
};

const DROPPED_FOR_PROJECT_DROPPED = {
  id: DROP_FOR_PROJECT_DROPPED,
  value: 'projectDropped',
  text: 'The project was dropped',
};

export const MENTOR_APP_DROP_OPTIONS = {
  DROPPED_UNRESPONSIVE,
  DROPPED_NOT_A_FIT,
  DROPPED_BAD_EXPERIENCE_PM,
  DROPPED_BAD_ENT_EXPERIENCE,
  DROPPED_BAD_TIMING,
  DROPPED_NO_INTEREST_IN_PROJECTS,
  DROPPED_REJECTED_BY_ENT,
  DROPPED_FOR_PROJECT_DROPPED,
};

export const MENTOR_STATUSES_DROPPED = [
  DROPPED_UNRESPONSIVE,
  DROPPED_NOT_A_FIT,
  DROPPED_BAD_EXPERIENCE_PM,
  DROPPED_BAD_ENT_EXPERIENCE,
  DROPPED_BAD_TIMING,
  DROPPED_NO_INTEREST_IN_PROJECTS,
  DROPPED_REJECTED_BY_ENT,
];

const getSelection = t => ({
  id: STATUS_PENDING_DECISION,
  value: 'selection',
  text: t('Move to Selection'),
  view: 'selection',
});

const getFollowUpOption = t => ({
  id: STATUS_APPROVED_NO_EMAIL,
  value: 'manualFollowUp',
  text: t('Accept, but no email sent'),
  view: 'approved'
});

const getApprovedOption = t => ({
  id: STATUS_APPROVED_ONBOARDING_SESSION,
  value: 'approved',
  text: t('Accept & Attend Onboarding Session'),
  view: 'approved',
  needsOnboarding: true,
});

const getMatching = t => ({
  id: STATUS_UNMATCHED,
  value: 'matching',
  text: t('Accept & move directly to MATCHING'),
  view: 'matching',
});

export const getMentorAcceptedOptions = t => ({
  SELECTION: getSelection(t),
  APPROVED: getFollowUpOption(t),
  APPROVED_ONBOARDING: getApprovedOption(t),
  MATCHING: getMatching(t),
});

const INCOMPLETE = {
  id: REJ_INCOMPLETE,
  value: 'weak_application',
  text: 'Incomplete / weak application',
};

const PROF_EXPERIENCE = {
  id: REJ_PROF_EXPERIENCE,
  value: 'professional_professional',
  text: 'Lack of professional experience',
};

const ENT_KNOWLEDGE = {
  id: REJ_ENT_KNOWLEDGE,
  value: 'entrepreneurship_knowledge',
  text: 'Lack of entrepreneurship knowledge',
};

const LAN_PROFICIENCY = {
  id: REJ_LAN_PROFICIENCY,
  value: 'language_proficiency',
  text: 'Lack of language proficiency',
};

const PROGRAM_REQUIREMENTS = {
  id: REJ_PROGRAM_REQUIREMENTS,
  value: 'program_requirements',
  text: 'Does not meet program requirements',
};

const OTHER = {
  id: REJ_OTHER,
  value: 'other',
  text: 'Other (no email sent)',
};

export const MENTOR_REJECTED_OPTIONS = {
  INCOMPLETE,
  PROF_EXPERIENCE,
  ENT_KNOWLEDGE,
  LAN_PROFICIENCY,
  PROGRAM_REQUIREMENTS,
  OTHER,
};

export const getRejectedOrStatusDisplay = (format, t, rejected, status) => {
  // If the application has not been rejected, show application data.
  if (rejected === null || rejected === undefined) {
    return status !== null || status !== undefined
      ? getStatusDisplayNewPMPanel(t, status)
      : t('Not Started');
  }
  // Otherwise, show the rejected status.
  return format(t('Rejected due to %(rejected_reason)s'), {
    rejected_reason: getRejectedStatusDisplay(t, rejected),
  });
};

export const mentorsFilterApplicationStatusAndRejected = (t, applicationStatus) => {
  switch (applicationStatus) {
    case 'approved':
      return [
        {
          text: t('Approved - Pending Steps'),
          value: STATUS_APPROVED_NO_EMAIL,
        },
        {
          text: t('Approved - Onboarding Call'),
          value: STATUS_APPROVED_ONBOARDING_SESSION,
        },
      ];
    default:
      return [
        {
          text: t('Application Incomplete'),
          value: STATUS_IN_PROGRESS,
        },
        {
          text: t('Application Pending'),
          value: STATUS_PENDING_DECISION,
        },
        {
          text: t('Approved - Pending Steps'),
          value: STATUS_APPROVED_NO_EMAIL,
        },
        {
          text: t('Approved - Onboarding Call'),
          value: STATUS_APPROVED_ONBOARDING_SESSION,
        },
        {
          text: t('Un-matched'),
          value: STATUS_UNMATCHED,
        },
        {
          text: t('Matched'),
          value: STATUS_MATCHED,
        },
        {
          text: t('Mentoring'),
          value: STATUS_INCUBATING,
        },
        {
          text: t('Completed'),
          value: STATUS_ALUMNI,
        },
        {
          text: t('Rejected due to...'),
          value: 'Rejected',
          children: [
            {
              text: t('Incomplete / weak application'),
              value: 10,
            },
            {
              text: t('Lack of professional experience'),
              value: 11,
            },
            {
              text: t('Lack of entrepreneurship knowledge'),
              value: 12,
            },
            {
              text: t('Lack of language proficiency'),
              value: 13,
            },
            {
              text: t('Does not meet program requirements'),
              value: 14,
            },
            {
              text: t('Other (no email sent)'),
              value: 15,
            },
          ],
        },
      ];
  }
};

const MentorApplicationRecord = Record({
  id: null,
  mentor: null,
  program: null,
  additional_answer_1: null,
  additional_answer_2: null,
  additional_answer_3: null,
  additional_answer: null,
  complete_surveys: null,
  notes: null,
  matching_notes: '',
  application_notes: '',
  dropped_notes: '',
  incubating_notes: '',
  last_follow_up_date: null,
  participate_in_another_program_if_rejected: null,
  program_type: null,
  progress: null,
  ranking_max_projects: null,
  invites_pending_sign: 0,
  ranking_open_to_comentor: null,
  ranking_reasons: null,
  ranking_submitted: null,
  ranking: null,
  rejected: null,
  remark: null,
  review_status: null,
  STATUS_APPROVED_NO_EMAIL,
  STATUS_ALUMNI,
  STATUS_IN_PROGRESS,
  STATUS_INCUBATING,
  STATUS_MATCHED,
  STATUS_PENDING_DECISION,
  STATUS_APPROVED_ONBOARDING_SESSION,
  STATUS_UNMATCHED,
  status: null,
  survey_responses_eoi_ment: null,
  survey_responses_pc_ment: null,
});

class MentorApplication extends MentorApplicationRecord {
  static STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;

  static STATUS_PENDING_DECISION = STATUS_PENDING_DECISION;

  static STATUS_APPROVED_NO_EMAIL = STATUS_APPROVED_NO_EMAIL;

  static STATUS_APPROVED_ONBOARDING_SESSION = STATUS_APPROVED_ONBOARDING_SESSION;

  static STATUS_UNMATCHED = STATUS_UNMATCHED;

  static STATUS_MATCHED = STATUS_MATCHED;

  static STATUS_INCUBATING = STATUS_INCUBATING;

  static STATUS_ALUMNI = STATUS_ALUMNI;

  static REJ_INCOMPLETE = REJ_INCOMPLETE;

  static REJ_PROF_EXPERIENCE = REJ_PROF_EXPERIENCE;

  static REJ_ENT_KNOWLEDGE = REJ_ENT_KNOWLEDGE;

  static REJ_LAN_PROFICIENCY = REJ_LAN_PROFICIENCY;

  static REJ_PROGRAM_REQUIREMENTS = REJ_PROGRAM_REQUIREMENTS;

  static REJ_OTHER = REJ_OTHER;

  get id() {
    return this.get('id');
  }

  getStatusDisplay = t => getStatusDisplay(t, this.get('status'));

  getRejectedStatusDisplay = t => getRejectedStatusDisplay(t, this.get('rejected'));
}

export default MentorApplication;
