import types from 'store/modules/user/types';

const userLogin = ({ username, password, callback }) => ({
  type: types.USER_LOGIN,
  payload: { username, password, callback },
});

const userRestore = () => ({
  type: types.USER_RESTORE,
});

const userRegister = ({ username, password, member, mentor, organization, program, callback }) => ({
  type: types.USER_REGISTER,
  payload: {
    username,
    password,
    member,
    mentor,
    organization,
    program,
    callback,
  },
});

const userRegisterSucceeded = ({ userData }) => ({
  type: types.USER_REGISTER_SUCCEEDED,
  payload: userData,
});

const userRegisterFailed = ({ message }) => ({
  type: types.USER_REGISTER_FAILED,
  payload: message,
});

const userIsAccessible = () => ({
  type: types.USER_ACCESSIBILITY,
});

const userLinkRequest = query => ({
  type: types.USER_LINK_REQUEST,
  payload: query,
});

const userLinkSucceeded = ({ userLinkData }) => ({
  type: types.USER_LINK_SUCCEEDED,
  payload: userLinkData,
});

const userLinkFailed = ({ message }) => ({
  type: types.USER_LINK_FAILED,
  payload: message,
});

const userFetchRequest = ({ callback } = {}) => ({
  type: types.USER_FETCH_REQUESTED,
  payload: { callback },
});

const userFetchSucceeded = ({ userData }) => ({
  type: types.USER_FETCH_SUCCEEDED,
  payload: userData,
});

const userFetchFailed = ({ message }) => ({
  type: types.USER_FETCH_FAILED,
  payload: message,
});

const clearError = () => ({
  type: types.USER_CLEAR_ERROR,
});

const forgotPassword = payload => ({
  type: types.USER_FORGOT_PASSWORD,
  payload,
});

const forgotPasswordSucceeded = () => ({
  type: types.USER_FORGOT_PASSWORD_SUCCEEDED,
});

const forgotPasswordFailed = ({ message }) => ({
  type: types.USER_FORGOT_PASSWORD_FAILED,
  payload: message,
});

const userEdit = profileData => ({
  type: types.USER_EDIT,
  payload: profileData,
});

const userEditSucceeded = ({ profileData }) => ({
  type: types.USER_EDIT_SUCCEEDED,
  payload: profileData,
});

const userEditFailed = ({ message }) => ({
  type: types.USER_EDIT_FAILED,
  payload: message,
});

const resetPassword = ({ uid, token, user, password }, callback) => ({
  type: types.USER_RESET_PASSWORD,
  payload: {
    uid,
    token,
    user,
    password,
  },
  callback,
});

const resetPasswordSucceeded = ({ response }) => ({
  type: types.USER_RESET_PASSWORD_SUCCEEDED,
  payload: response,
});

const resetPasswordFailed = ({ message }) => ({
  type: types.USER_RESET_PASSWORD_FAILED,
  payload: message,
});

export default {
  clearError,
  forgotPassword,
  forgotPasswordFailed,
  forgotPasswordSucceeded,
  resetPassword,
  resetPasswordFailed,
  resetPasswordSucceeded,
  userEdit,
  userEditFailed,
  userEditSucceeded,
  userFetchFailed,
  userFetchRequest,
  userFetchSucceeded,
  userIsAccessible,
  userLinkFailed,
  userLinkRequest,
  userLinkSucceeded,
  userLogin,
  userRegister,
  userRegisterFailed,
  userRegisterSucceeded,
  userRestore,
};
