import { Record } from 'immutable';
import defaultPic from 'static/images/profile-default-old.png';
import { exportImageURL as baseURL } from 'services/api';

const ProfilePictureRecord = Record({
  id: null,
  user: null,
  photo: '',
  status: 0,
  type: null,
});

class ProfilePicture extends ProfilePictureRecord {
  static CUSTOM = 0;

  static MALE = 1;

  static FEMALE = 2;

  static NEUTRAL = 3;

  get photo() {
    if (this.get('photo')?.includes('http')) {
      return this.get('photo');
    }
    return baseURL + this.get('photo');
  }

  get status() {
    return this.get('status');
  }

  get_type_display(t) {
    return {
      [ProfilePicture.CUSTOM]: t('Custom'),
      [ProfilePicture.MALE]: t('Male'),
      [ProfilePicture.FEMALE]: t('Female'),
      [ProfilePicture.NEUTRAL]: t('Neutral'),
    }[this.get('type')];
  }

  static getUrl(member, mentor, profPictureData, defaultPictureData) {
    const profile = member && member.id ? member : mentor;
    if (!profile.picture) {
      return defaultPic;
    }
    if (Number.isInteger(profile.picture) && profPictureData.size > 0) {
      const index = profPictureData.findIndex(pic => pic.id === profile.picture);
      if (index !== -1) {
        const photo = profPictureData.get(index).get('photo');
        if (photo.includes('http')) {
          return photo;
        }
        return baseURL + photo;
      }
    }
    if (Number.isInteger(profile.picture) && defaultPictureData.size > 0) {
      const index = defaultPictureData.findIndex(pic => pic.id === profile.picture);
      if (index !== -1) {
        const photo = defaultPictureData.get(index).get('photo');
        if (photo.includes('http')) {
          return photo;
        }
        return baseURL + photo;
      }
    }
    return profile.picture.photo;
  }
}

export default ProfilePicture;
