import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { P } from 'components/fontSystem';

import TranslationsContext from 'providers/translationsProvider';

import Icon from '@ant-design/icons';
import IconCalendar from '../IconCalendar';

import { memberTheme, mentorTheme } from 'styles/themes';
import './styles.css';
import bfbTheme from 'styles/b4b-theme';

const DatePicky = props => {
  const {
    onSelect,
    name,
    isMentor = false,
    disabled = false,
    disabledDate = false,
    initialValue = undefined,
    last = null,
    nextValue = undefined,
    showInfoNext = false,
    noLine = false,
    ...rest
  } = props;

  const { format, t } = React.useContext(TranslationsContext);

  const handleOnChange = date => {
    // PMs only choose a day, we set the time by default at start or end of the day
    if (date && name.includes('start')) {
      const startDate = date.startOf('date').utc(true);
      onSelect({ target: { name, value: startDate } });
    } else if (date && (name.includes('end') || name.includes('decision'))) {
      const endDate = date.endOf('date').utc(true);
      onSelect({ target: { name, value: endDate } });
    }
  };

  return (
    <ThemeProvider theme={isMentor ? mentorTheme : memberTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '175%',
          justifyContent: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <StyledDatePicker
            name={name}
            suffixIcon={<Icon component={IconCalendar} />}
            onChange={handleOnChange}
            disabledDate={disabledDate}
            disabled={disabled}
            format={t('YYYY/MM/DD')}
            hasValue={initialValue}
            value={initialValue ? dayjs(initialValue).utc() : undefined}
            renderExtraFooter={() =>
              format(t('In your timezone: %(datetime)s'), {
                datetime: dayjs(initialValue).format('MMM D [of] YYYY [at] HH:mm'),
              })
            }
            {...rest}
          />
        </div>
        {!noLine && <BaseLine last={last} />}
        {!noLine && initialValue && (
          <ConnectingLine
            forMentor={isMentor}
            nextValue={nextValue}
            initialValue={Boolean(initialValue)}
            last={last}
          />
        )}
        {showInfoNext && initialValue && (
          <InfoNext color={bfbTheme.hyperlink} $italic $mb0>
            {t('Program dates / next stage: Matching >')}
          </InfoNext>
        )}
      </div>
    </ThemeProvider>
  );
};

export default DatePicky;

DatePicky.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  isMentor: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledDate: PropTypes.bool,
  last: PropTypes.bool,
  nextValue: PropTypes.string,
  matching: PropTypes.bool,
  showInfoNext: PropTypes.bool,
  noLine: PropTypes.bool,
};

const StyledDatePicker = styled(DatePicker)`
  width: 150px;
  height: 40px;
  background-color: ${({ hasValue, theme }) => (hasValue ? 'white' : theme.primaryColor)};
  border: ${({ hasValue, theme }) =>
    !hasValue ? '1px solid white' : `1px solid ${theme.primaryColor}`};
  z-index: 9;

  &.ant-picker-disabled {
    background-color: ${({ hasValue, theme }) => (hasValue ? 'white' : theme.primaryColor)};
    opacity: 0.5;
  }

  .ant-picker-input {
    input::placeholder {
      color: ${({ hasValue, theme }) => (hasValue ? theme.primaryColor : 'white')};
    }

    input {
      color: ${({ hasValue, theme }) => (hasValue ? theme.primaryColor : 'white')};
    }

    .ant-picker-suffix .anticon {
      color: ${({ hasValue, theme }) => (hasValue ? theme.primaryColor : 'white')} !important;
    }
  }

  input.ant-calendar-picker-input:hover {
    background-color: ${({ hasValue, theme }) => (hasValue ? 'white' : theme.primaryColor)};
    border: ${({ hasValue, theme }) =>
      !hasValue ? '1px solid white' : `1px solid ${theme.primaryColor}`};
  }
`;

const InfoNext = styled(P)`
  position: absolute;
  top: 100px;
  right: 0;
`;

const BaseLine = styled.div`
  display: flex;
  position: absolute;
  width: ${({ last }) => (last ? '60%' : '70%')};
  height: 10px;
  margin-top: 14px;
  background: #f2f2f2;
  margin-left: 150px;
  z-index: 0;
  border-radius: ${({ last }) => (last ? '7px' : 'none')};
`;

const ConnectingLine = styled.div`
  display: flex;
  position: relative;
  height: 10px;
  margin-top: 14px;
  width: 150%;
  border-radius: 7px;
  background: ${({ theme }) => `linear-gradient(90deg, #FFFFFF, ${theme.primaryColor})`};
  animation: ${({ nextValue, initialValue, last }) =>
    !nextValue && initialValue && !last ? 'toTheNextOne 4s ease infinite' : 'none'};
  z-index: 1;

  @keyframes toTheNextOne {
    from {
      width: 0px;
    }
    to {
      width: 100%;
      border-radius: 0px;
    }
  }
`;
