import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(LocalizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isBetween);

export const MADRID_TIMEZONE = 'Europe/Madrid';

// Option 1: Full Maintenance mode / view
export const MAINTENANCE_MODE = false;

// Option 2: Alert users with a banner and AUTOMATICALLY set maintenance ON
export const MAINTENANCE_DATE = null // dayjs.tz('2025-02-11 09:00:00', MADRID_TIMEZONE);

export const MAINTENANCE_DURATION = 1; // Number of hours
export const MAINTENANCE_ANNOUNCEMENT = true; // Display a Banner and notifications when maintenance is close

// ///////////////////////////////////////////////// //
// You don't need to change anything below this line //
// ///////////////////////////////////////////////// //
export const MAINTENANCE_END_DATE =
  MAINTENANCE_DATE && MAINTENANCE_DATE.add(MAINTENANCE_DURATION, 'hours');

const MAINTENANCE_TIMEZONE = 'CET (Madrid time)';
const MAINTENANCE_CLOSE_INTERVAL = 30; // minutes

export const getMaintenanceMessage = (format, t) =>
  MAINTENANCE_DATE &&
  format(
    t(
      'We will be performing a routine maintenance on our system on %(maintenanceStartDate)s from %(maintenanceStartHour)s - %(maintenanceEndHour)s %(maintenanceTimezone)s.'
    ),
    {
      maintenanceStartDate: MAINTENANCE_DATE.format(t('dddd, MMMM Do')),
      maintenanceStartHour: MAINTENANCE_DATE.format('LT'),
      maintenanceEndHour: MAINTENANCE_END_DATE.format('LT'),
      maintenanceTimezone: MAINTENANCE_TIMEZONE,
    }
  );

export const isMaintenanceComingSoon = () =>
  MAINTENANCE_ANNOUNCEMENT && dayjs.tz(dayjs(), MADRID_TIMEZONE).isBefore(MAINTENANCE_DATE);

export const isMaintenanceModeEnabled = () =>
  MAINTENANCE_MODE ||
  (MAINTENANCE_DATE &&
    dayjs.tz(dayjs(), MADRID_TIMEZONE).isBetween(MAINTENANCE_DATE, MAINTENANCE_END_DATE));

export const isMaintenanceClose = (minutes = MAINTENANCE_CLOSE_INTERVAL) =>
  !isMaintenanceModeEnabled() && MAINTENANCE_DATE.diff(dayjs(), 'minutes') < minutes;

export const getMaintenanceNotificationTimeout = () =>
  MAINTENANCE_DATE.diff(dayjs()) - MAINTENANCE_CLOSE_INTERVAL * 60 * 1000;

export const getMaintenanceRedirectTimeout = () =>
  MAINTENANCE_DATE && isMaintenanceClose(60) && MAINTENANCE_DATE.diff(dayjs());
