// Libraries
import styled, { css } from 'styled-components';

// Components
import { Tag } from 'antd';
import MemberApplication from 'models/MemberApplication';
import MentorApplication from 'models/MentorApplication';

const getBackgroundColor = props => {
  const { status } = props;

  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
    case MentorApplication.STATUS_IN_PROGRESS:
      return '#37CC821A';
    case MentorApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_PENDING_DECISION:
      return '#E6F8FB';
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#E8F1ED';
    case MemberApplication.STATUS_UNMATCHED:
    case MentorApplication.STATUS_UNMATCHED:
    case MemberApplication.STATUS_MATCHED:
    case MentorApplication.STATUS_MATCHED:
      return '#EEEEFC';

    case MemberApplication.STATUS_INCUBATING:
    case MentorApplication.STATUS_INCUBATING:
      return '#E7EBF9';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getBackgroundMentorColor = props => {
  const { status } = props;

  switch (status) {
    case MentorApplication.STATUS_IN_PROGRESS:
      return '#37CC821A';
    case MentorApplication.STATUS_PENDING_DECISION:
      return '#E6F8FB';
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#E8F1ED';
    case MentorApplication.STATUS_UNMATCHED:
    case MentorApplication.STATUS_MATCHED:
      return '#EEEEFC';
    case MentorApplication.STATUS_INCUBATING:
      return '#E7EBF9';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getTextColor = props => {
  const { status } = props;

  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
    case MentorApplication.STATUS_IN_PROGRESS:
      return '#37CC82';
    case MentorApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_PENDING_DECISION:
      return '#0FC5D8';
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#1A754E';
    case MemberApplication.STATUS_UNMATCHED:
    case MentorApplication.STATUS_UNMATCHED:
    case MemberApplication.STATUS_MATCHED:
    case MentorApplication.STATUS_MATCHED:
      return '#5C5CEA';
    case MemberApplication.STATUS_INCUBATING:
    case MentorApplication.STATUS_INCUBATING:
      return '#123DC6';
    default:
      return '#999999';
  }
};

const getTextMentorColor = props => {
  const { status } = props;

  switch (status) {
    case MentorApplication.STATUS_IN_PROGRESS:
      return '#37CC82';
    case MentorApplication.STATUS_PENDING_DECISION:
      return '#0FC5D8';
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#1A754E';
    case MentorApplication.STATUS_UNMATCHED:
    case MentorApplication.STATUS_MATCHED:
      return '#5C5CEA';
    case MentorApplication.STATUS_INCUBATING:
      return '#123DC6';
    default:
      return '#999999';
  }
};

export const StyledStageMemberTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundColor} !important;
  color: ${getTextColor} !important;
  text-transform: capitalize;
  cursor: initial;
  border: none;
  display: inline-flex;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '0 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
  // transition: all ease 300ms;
`;

export const StyledStageMentorTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundMentorColor} !important;
  color: ${getTextMentorColor} !important;
  text-transform: capitalize;
  cursor: initial;
  border: none;
  display: inline-flex;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '0 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
  // transition: all ease 300ms;
`;
